<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent">
        <div class="my-card">
          <van-field
            required
            v-model="viewData.FolderName"
            name="FolderName"
            label="文件夹名称"
            maxlength="20"
            placeholder="文件夹名称"
            :rules="[{ required: true, message: '请填写文件夹名称' }]"
          />
        </div>
        <!-- <div class="my-card">
          <van-field name="accessoryList" label="班级圈照片">
            <template #input>
              <van-uploader
                v-model="viewData.accessoryList"
                :deletable="true"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div> -->
        <van-button
          :loading="loading"
          block
          color="#47AFA7"
          type="info"
          native-type="submit"
          >完成</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Checkbox,
  CheckboxGroup,
  Toast,
} from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
  },
  data() {
    return {
      id: null,
      viewData: {},
      peopleList: [],
      peopleIds: [],
      showPicker: false,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
    // 获取接收对象列表
    this.getClassList();
  },
  methods: {
    getClassList() {
      this.$axios.get("/api/Class/GetClassByApp").then((res) => {
        if (res.code === 200) {
          this.peopleList = res.data;
        } else {
          this.$toast.fail(res.msg || "操作失败");
        }
      });
    },
    getDetail() {
      this.$axios
        .get("/api/ClassFolder/Get", {
          ID: this.id,
        })
        .then((res) => {
          this.viewData = res.data;
        });
    },
    onConfirm(value) {
      this.viewData.AcceptUserName = value.realName;
      this.viewData.AcceptUserID = value.UserID;
      this.showPicker = false;
    },
    beforeRead(file) {
      Toast.loading({
        message: "加载中...",
        duration: 0,
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);
      this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
        Toast.clear();
        if (res.code === 200) {
          this.viewData.accessoryList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
            url: res.data.url,
          });
          return true;
        } else {
          this.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    selectFn(v) {
      let arr = [];
      v.map((item) => {
        arr.push(item.ClassName);
      });
      this.viewData.PeopleIdList = arr.join();
    },
    save() {
      let that = this;
      let arr = [];
      this.peopleIds.map((item) => {
        arr.push(item.ID);
      });
      delete this.viewData.PeopleIdList;
      this.$axios
        .post(this.id ? "/api/ClassFolder/Edit" : "/api/ClassFolder/Add", {
          ...this.viewData,
          ClassIDList: arr,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.msg || "操作成功");
            setTimeout(() => {
              that.$router.replace({
                path: "ClassCircle",
                query: {
                  active: 3,
                },
              });
            }, 1000);
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
